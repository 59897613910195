
/**
 * An array of objects representing notification templates.
 * Each object contains a type and an icon to be used for the notification.
 */
export const notificationTemplate: any[] = [
    {
      type: 'addCategory',
      icon: 'create_new_folder'
    },
    {
      type: 'deleteCategory',
      icon: 'delete_folder'
    },
    {
      type: 'addProduct',
      icon: 'add_shopping_cart'
    },
    {
      type: 'removeProduct',
      icon: 'remove_shopping_cart'
    },
    {
      type: 'addEmployee',
      icon: 'person_add'
    },
    {
      type: 'updateMerchant',
      icon: 'edit'
    },
    {
      type: 'connectStripe',
      icon: 'heroicons_solid:link'
    },
    {
      type: 'revokeStripe',
      icon: 'heroicons_solid:link'
    },
    {
      type: 'connectInstagram',
      icon: 'heroicons_solid:link'
    },
    {
      type: 'revokeInstagram',
      icon: 'heroicons_solid:link'
    },
    {
      type: 'connectShippo',
      icon: 'heroicons_solid:link'
    },
    {
      type: 'revokeShippo',
      icon: 'heroicons_solid:link'
    },
    {
      type: 'connectGoogle',
      icon: 'heroicons_solid:link'
    },
    {
      type: 'revokeGoogle',
      icon: 'heroicons_solid:link'
    },
    {
      type: 'payOrder',
      icon: 'receipt_long'
    },
    {
      type: 'jobFinished',
      icon: 'cloud_done'
    }
];